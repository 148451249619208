export function formataCpfCnpj(cpfCnpj) {
  if (cpfCnpj === null || cpfCnpj === undefined) return
  if (cpfCnpj.length === 11) {
    return cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  }
  return cpfCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
}

export function formatDate(date) {
  if (date === null) return
  if (date.length === 0) return
  const [anoIni, mesIni, diaIni] = date.split('T')[0].split('-')
  return `${diaIni}/${mesIni}/${anoIni}`
}

export function formatDateTime(date) {
  if (date === null) return
  if (date.length === 0) return
  const [anoIni, mesIni, diaIni] = date.split('T')[0].split('-')
  const [hora, minuto] = date.substring(date.indexOf('T') + 1, date.length).split(':')
  return `${diaIni}/${mesIni}/${anoIni} ${hora}:${minuto}`
}

export function formatDateTimeWithSeconds(date) {
  if (date === null) return
  if (date.length === 0) return
  const [anoIni, mesIni, diaIni] = date.split('T')[0].split('-')
  const [hora, minuto] = date.substring(date.indexOf('T') + 1, date.length).split(':')
  const segundo = date
    .substring(date.indexOf('T') + 1, date.length)
    .split(':')[2]
    .split('.')[0]
  return `${diaIni}/${mesIni}/${anoIni} ${hora}:${minuto}:${segundo}`
}

export function parseDate(date) {
  if (!date) return null
  const [day, month, year] = date.split('/')
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
}

export function formatNumber(value) {
  return value.toLocaleString('pt-BR', {
    minimumFractionDigits: 2
  })
}
