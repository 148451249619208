<template>
  <v-container fluid>
    <v-card class="mt-2">
      <v-container fluid>
        <v-data-table
          :headers="headers"
          :loading="buscandoEmpresa"
          loading-text="Carregando as empresas..."
          :items="empresas"
          item-class="class"
          :footer-props="{ itemsPerPageOptions: [5, 10, 15, 30, 50], showFirstLastPage: true }"
        >
          <template #top>
            <v-container fluid class="d-flex">
              <v-toolbar-title> Empresas </v-toolbar-title>
              <v-spacer />

              <v-dialog v-model="dialog" max-width="550px">
                <template #activator="{ on, attrs }">
                  <v-btn color="primary" v-bind="attrs" :disabled="!permiteAdicionar" v-on="on">
                    Adicionar
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-form ref="form" v-model="validForm" @submit.prevent>
                      <v-container>
                        <v-row>
                          <v-col v-if="editedIndex !== -1" cols="6">
                            <v-switch v-model="editedItem.ativa" color="green" label="Ativa" />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-text-field
                              v-model="editedItem.nome"
                              label="Nome"
                              required
                              :rules="[obrigatorio]"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <CampoDeCpfOuCnpj
                              v-model="editedItem.documento"
                              label="CPF / CNPJ"
                              :disabled="editedIndex !== -1"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <v-text-field
                              v-model="editedItem.email"
                              label="Email"
                              :rules="[obrigatorio, emailInvalido]"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-alert v-if="showMessageError" dense outlined type="error">
                              {{ messageError }}
                            </v-alert>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="fecharDialog">Cancelar</v-btn>
                    <v-btn
                      color="green darken-1"
                      text
                      :disabled="!validForm"
                      :loading="salvando"
                      @click="salvarEmpresa"
                    >
                      Salvar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-container>
          </template>

          <template #[`item.ativa`]="{ item }">
            <v-icon v-if="item.ativa" color="green">mdi-check</v-icon>
            <v-icon v-else color="red">mdi-close</v-icon>
          </template>

          <template #[`item.documento`]="{ item }">
            {{ formataCpfCnpj(item.documento) }}
          </template>

          <template #[`item.acoes`]="{ item }">
            <div class="d-flex flex-nowrap justify-center">
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-icon
                    class="mr-2"
                    :disabled="!permiteEditar"
                    v-on="on"
                    @click="editarEmpresa(item)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>

                <span>Editar</span>
              </v-tooltip>

              <v-tooltip top>
                <template #activator="{ on }">
                  <v-icon
                    class="mr-2"
                    :disabled="!permiteRemover"
                    v-on="on"
                    @click="abrirDialogExclusaoEmpresa(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>

                <span> Excluir </span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-container>

      <SnackbarMessage
        :show-snack.sync="snackbar.showSnackParam"
        :snack-text="snackbar.snackTextParam"
        :snack-type="snackbar.snackTypeParam"
      />

      <v-dialog v-model="dialogExclusaoEmpresa" persistent max-width="500px">
        <v-card>
          <v-card-title class="headline">Excluir</v-card-title>

          <v-card-text> Tem certeza que deseja remover esta empresa? </v-card-text>

          <v-card-text>
            <v-alert v-if="showMessageError" dense outlined type="error">
              {{ messageError }}
            </v-alert>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="red darken-1" text @click="fecharDialogExclusaoEmpresa"> Cancelar </v-btn>
            <v-btn color="green darken-1" text :loading="excluindoEmpresa" @click="excluirEmpresa">
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import EmpresaService from '@/services/organizacao/empresa'
import Empresa from '@/models/organizacao/empresa'
import Snackbar from '@/models/ui/snackbar'
import SnackbarMessage from '@/components/ui/SnackbarMessage'
import CampoDeCpfOuCnpj from '@/components/ui/CampoDeCpfOuCnpj'
import { obrigatorio, emailInvalido } from '@/shareds/regrasForm'
import { formataCpfCnpj } from '@/shareds/formatacoes'
import store from '@/store'

export default {
  name: 'Empresa',

  components: {
    SnackbarMessage,
    CampoDeCpfOuCnpj
  },

  data: () => ({
    empresas: [],
    itensEmpresa: [],
    headers: [
      { text: 'Nome', value: 'nome' },
      { text: 'Email', value: 'email' },
      { text: 'Documento', value: 'documento' },
      { text: 'Ativa', value: 'ativa' },
      { text: '', value: 'acoes', width: '1%' }
    ],
    panel: true,
    buscandoEmpresa: false,
    excluindoEmpresa: false,
    salvando: false,
    dialog: false,
    editedItem: new Empresa(),
    editedIndex: -1,
    itemEmpresaExclusao: new Empresa(),
    indiceEmpresaExclusao: 0,
    dialogExclusaoEmpresa: false,
    validForm: true,
    obrigatorio: obrigatorio,
    emailInvalido: emailInvalido,
    formataCpfCnpj: formataCpfCnpj,
    showMessageError: false,
    snackbar: new Snackbar()
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Adicionar empresa' : 'Editar empresa'
    },
    permiteAdicionar() {
      return store.getters.verificaPermissaoAcesso('Empresa', 'ADICIONAR')
    },
    permiteEditar() {
      return store.getters.verificaPermissaoAcesso('Empresa', 'EDITAR')
    },
    permiteRemover() {
      return store.getters.verificaPermissaoAcesso('Empresa', 'REMOVER')
    }
  },

  created() {
    this.buscarEmpresas()
  },

  methods: {
    buscarEmpresas() {
      this.buscandoEmpresa = true

      EmpresaService.buscarTudo()
        .then((response) => (this.empresas = response))
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar os empresas.'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
        .finally(() => (this.buscandoEmpresa = false))
    },

    editarEmpresa(item) {
      this.editedIndex = this.empresas.indexOf(item)
      this.editedItem = { ...item }
      this.dialog = true
    },

    abrirDialogExclusaoEmpresa(item) {
      this.indiceEmpresaExclusao = this.empresas.indexOf(item)
      this.itemEmpresaExclusao = this.empresas[this.indiceEmpresaExclusao]
      if (!this.itemEmpresaExclusao.id) return
      this.dialogExclusaoEmpresa = true
    },

    excluirEmpresa() {
      this.excluindoEmpresa = true

      EmpresaService.excluir(this.itemEmpresaExclusao.id)
        .then(() => {
          this.fecharDialogExclusaoEmpresa()
          this.snackbar.snackTextParam = 'Empresa excluída com sucesso.'
          this.snackbar.snackTypeParam = 'sucess'
          this.snackbar.showSnackParam = true
          this.empresas.splice(this.indiceEmpresaExclusao, 1)
        })
        .catch((erro) => {
          this.messageError = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao excluir a empresa.'
          }`
          this.showMessageError = true
        })
        .finally(() => (this.excluindoEmpresa = false))
    },

    async salvarEmpresa() {
      this.showMessageError = false
      this.$refs.form.validate()

      if (!this.validForm) return

      this.salvando = true

      if (this.editedIndex > -1) {
        EmpresaService.atualizar(this.editedItem.id, this.editedItem)
          .then(() => {
            this.fecharDialog()
            this.snackbar.snackTextParam = 'Empresa atualizada com sucesso.'
            this.snackbar.snackTypeParam = 'sucess'
            this.snackbar.showSnackParam = true
            Object.assign(this.empresas[this.editedIndex], this.editedItem)
          })
          .catch((erro) => {
            this.messageError = `${erro.response.status} | ${
              erro.response.data.message === undefined
                ? erro.message
                : erro.response.data.message
                ? erro.response.data.message
                : 'Ocorreu um erro ao atualizar o empresa.'
            }`
            this.showMessageError = true
          })
          .finally(() => (this.salvando = false))
      } else {
        EmpresaService.cadastrar({
          ...this.editedItem,
          ativa: true,
          documento: this.editedItem.documento
            .replaceAll('.', '')
            .replaceAll('/', '')
            .replaceAll('-', '')
        })
          .then((response) => {
            this.fecharDialog()
            this.snackbar.snackTextParam = 'Empresa cadastrado com sucesso.'
            this.snackbar.snackTypeParam = 'sucess'
            this.snackbar.showSnackParam = true
            this.empresas.push(response)
          })
          .catch((erro) => {
            this.messageError = `${erro.response.status} | ${
              erro.response.data.message === undefined
                ? erro.message
                : erro.response.data.message
                ? erro.response.data.message
                : 'Ocorreu um erro ao cadastrar o empresa.'
            }`
            this.showMessageError = true
          })
          .finally(() => (this.salvando = false))
      }
    },

    fecharDialogExclusaoEmpresa() {
      this.showMessageError = false
      this.messageError = ''
      this.dialogExclusaoEmpresa = false
    },

    fecharDialog() {
      this.$refs.form.resetValidation()
      this.dialog = false
      this.showMessageError = false
      this.$nextTick(() => {
        this.editedItem = new Empresa()
        this.editedIndex = -1
      })
    }
  }
}
</script>
