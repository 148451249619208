import request from '@/common/request'

class EmpresaService {
  buscarTudo(params) {
    return request.get('/empresas', {
      params
    })
  }

  cadastrar(data) {
    return request.post('/empresas', data)
  }

  excluir(id) {
    return request.delete(`/empresas/${id}`)
  }

  atualizar(id, data) {
    return request.put(`/empresas/${id}`, data)
  }
}

export default new EmpresaService()
