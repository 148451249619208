import { isValidCNPJ, isValidCPF, isValidEmail } from '@brazilian-utils/brazilian-utils'

export const obrigatorio = (valor) => {
  return (!!valor && valor.toString().length > 0) || valor === 0 || 'Campo obrigatório'
}

export const maiorOuIgualAZero = (valor) => valor >= 0 || 'Deve ser um valor maior ou igual a zero'

export const maximoDigitos = (tamanho) => (valor) => {
  if (valor === undefined) return false
  return (valor !== null && valor.toString().length <= tamanho) || `Qtde máxima: ${tamanho} dígitos`
}

export const cnpjCpfInvalido = (valor) =>
  isValidCNPJ(valor) || isValidCPF(valor) || 'CNPJ/CPF inválido'

export const cnpjInvalido = (valor) => {
  if (valor === null || valor === '') return true
  return isValidCNPJ(valor) || 'CNPJ inválido'
}

export const emailInvalido = (valor) => !valor || isValidEmail(valor) || 'E-mail inválido'
