<template>
  <v-text-field
    v-mask="['###.###.###-##', '##.###.###/####-##']"
    v-bind="$attrs"
    :rules="[...(this.$attrs.rules || []), cnpjCpfInvalido]"
    v-on="$listeners"
  />
</template>

<script>
import { mask } from 'vue-the-mask'
import { cnpjCpfInvalido } from '@/shareds/regrasForm'

export default {
  directives: { mask },
  data: () => ({
    name: 'CampoDeCnpjOuCpf',
    cnpjCpfInvalido: cnpjCpfInvalido
  })
}
</script>
